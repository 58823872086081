import React, {useRef, forwardRef, useState, memo, useEffect} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { motion, useInView, useScroll, useMotionValueEvent } from "framer-motion";
import SplashVisualization from './SplashVisualization';
import ProductionNetwork from './ProductionNetwork';
import InputCategorization from './InputCategorization';
import IndustryOutputMap from './IndustryOutputMap';
import Tetris from './Tetris';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';
import headshot from './stu-headshot.jpg';
import diagramSingleIndustry from './diagram_single_industry.svg';
import diagramComplements from './diagram_complements.svg';
import diagramSubstitutes from './diagram_substitutes.svg';
import mpStudyAreas from './mp_study_areas_overview.jpg';
import mpResLidar from './mp_residential_lidar_data_points.jpg';
import mpResTIN from './mp_residential_tin_model.jpg';
import mpResDSM from './mp_residential_digital_surface_model.jpg';
import mpResSolarRadiation from './mp_residential_solar_radiation.jpg';
import mpResNDVI from './mp_residential_ndvi.jpg';
import mpResSuitableArea from './mp_residential_suitable_pv_area.jpg';
import isoneWindOutputWindSpeed from './isone_windoutput_windspeed.svg';
import isoneWindOutputWindGustSpeed from './isone_windoutput_windgustspeed.svg';
import isoneSolarOutputFairWeather from './isone_solaroutput_fairweather.svg';
import isoneNetImportsWindOutput from './isone_netimports_windoutput.svg';
import isoneNetImportsSolarOutput from './isone_netimports_solaroutput.svg';
import isoneExcel from './isone_windsolar_weather_netimports_exploration.xlsx';
import graphData from './production_network.json';
import categoryData from './input_categorization.csv';
import thumbnailProductionNetwork from './production_network_thumbnail.png';
import thumbnailInputCategorization from './input_categorization_thumbnail.png';
import thumbnailTetris from './tetris_thumbnail.png';
import thumbnailProductionMap from './production_map_thumbnail.png';

function TopBar(props) {
  return (
    <div className="menu_container">
      <div className="menu">
        <span className="topbar_name"><a href="/">STUART ILER</a></span>
        <input className="side-menu" type="checkbox" id="side-menu"/>
        <ul className="menu-list">
          <HashLink to="/about"><li className="menu-list-item">ABOUT</li></HashLink>
          <HashLink to="/skills"><li className="menu-list-item">SKILLS</li></HashLink>
          <HashLink to="/tools"><li className="menu-list-item">TOOLS</li></HashLink>
          <HashLink to="/projects"><li className="menu-list-item">PROJECTS</li></HashLink>
          <HashLink to="/contact"><li className="menu-list-item">CONTACT</li></HashLink>
        </ul>
        <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
      </div>
    </div>
  );
}

function BottomBar(props) {
  return (
    <div className="bottom_bar">
      <p><b>&copy; 2024 Stuart Iler</b></p>
    </div>
  );
}

const Splash = memo(function Splash(props) {
    return (
      <div id="splash_background">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="skill_highlights">
          <span><p><b>DATA</b></p></span>
          <span>
            <ul>
              <li><b>|</b> Visualization</li>
              <li><b>|</b> Dashboards</li>
              <li><b>|</b> Storytelling</li>
              <li><b>|</b> Strategy</li>
            </ul>
          </span>
        </div>
        <div className="splash_visualization">
          <SplashVisualization/>
        </div>
      </div>
    )
})

function FadeInDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          opacity: isInView ? 1 : 0,
          transition: "opacity 1.5s"
        }}
      >
        {children}
      </div>
  );
}

function FlyInLeftDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "transform 1s, opacity 1s"
        }}
      >
        {children}
      </div>
  );
}

function FlyInRightDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(100px)",
          opacity: isInView ? 1 : 0,
          transition: "transform 1s, opacity 1s"
        }}
      >
        {children}
      </div>
  );
}

const About = forwardRef(function About(props, ref) {
    return (
      <div id="about_background" ref={ref}>
        <div id="about" className="section_ID"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <FadeInDiv><div className="intro_text">
          <img src={headshot} />
          <div>
            <p>Welcome! I'm a mission-driven data consultant with a wide
            variety of experience. I love leveraging strategic thinking,
            visualization, writing, and other communications to help clients
            make the most of their data, understand past progress, and
            strategically plan for the future. I have topical expertise in
            economics and public policy with a focus on energy, climate, and
            sustainability.</p>
            <p>My work experience spans nonprofit, academic, government, and
            private contexts, including as an analytics manager, data scientist,
            and software engineer. I hold a Ph.D. in Public Policy from Harvard
            University (<a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank" rel="noreferrer">dissertation</a>),
            a Master of Environmental Management degree from Duke University (<a href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf" target="_blank" rel="noreferrer">thesis</a>),
            and a B.S. in Computer Science from U.C. San Diego.</p>
            <p>For details about my previous positions, education, and
            publications, please see my LinkedIn profile <a href="https://www.linkedin.com/in/stuartiler" target="_blank" rel="noreferrer">here</a>.
            For sample projects and code, please see below as well as my GitHub
            profile <a href="https://github.com/stuartiler/" target="_blank" rel="noreferrer">here</a>.</p>
          </div>
        </div></FadeInDiv>
      </div>
    );
})

const Skills = forwardRef(function Skills(props, ref) {
  return (
    <div id="skills_background" ref={ref}>
      <div id="skills" className="section_ID"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <FlyInLeftDiv><div className="header_statement header_left">Leveraging data to tell
      stories and provide insight.</div></FlyInLeftDiv>
      <FadeInDiv><div className="header_substatement header_left">I have been
      the lead data professional for multiple teams, responsible for setting
      strategic direction and ensuring datasets are used to advance
      organizational goals. One of my key responsibilities in these roles—as
      well as in other previous work—has been leveraging data to educate,
      inform, assess progress, and drive decision-making.</div></FadeInDiv>
      <div className="skills">
        <FadeInDiv>
          <span  className="icon"><FontAwesomeIcon icon={faChartSimple} size="2x" /></span>
          <span>I have extensive experience creating static and interactive
          data visualizations to understand trends, analyze relationships, and
          make decisions. I have also taken the lead role in metric design and
          implementation, ranging from ideation of what data to track to
          designing processes to bring indicators to life.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span className="icon"><FontAwesomeIcon icon={faPersonChalkboard} size="2x" /></span>
          <span>All my work has included a strong communications component,
          including translating technical, academic, and policy content for a
          variety of audiences ranging from the public to organizations'
          senior leadership. I have extensive writing experience as well as
          presentation, teaching, and training experience.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span className="icon"><FontAwesomeIcon icon={faCity} size="2x" /></span>
          <span>I have worked with a spectrum of datasets that span energy,
          air quality, employment, manufacturing, transportation, and more.
          These datasets have been both public and private and have required
          varying levels of pre-processing and quality control.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span className="icon"><FontAwesomeIcon icon={faMagnifyingGlassChart} size="2x" /></span>
          <span>I have advanced skills using statistical and machine
          learning techniques to extract insights from data and make
          predictions. I am well equipped to understand how clients are
          analyzing their data and to aid in interpreting and communicating
          key takeaways.</span>
        </FadeInDiv>
      </div>
    </div>
  );
})

const Tools = forwardRef(function Tools(props, ref) {
  return (
    <div id="tools_background" ref={ref} >
      <div id="tools" className="section_ID"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <FlyInRightDiv><div className="header_statement header_right">Using the
      right tools for the job.</div></FlyInRightDiv>
      <FadeInDiv><div className="header_substatement header_right">I have
      extensive experience with a variety of programming languages and software
      products. My broad background allows me to pick the right tool for the job
      and to understand clients' data architecture at both high and detailed
      levels.</div></FadeInDiv>
      <div className="tools">
          <FadeInDiv>
            <div>
              <span className="icon"><FontAwesomeIcon icon={faChartLine} size="1x" /></span>
              <span><b>Visualization</b></span>
            </div>
            <div>I have created a variety of interactive visualizations and
            dashboards using using <i>D3.js</i>, <i>Tableau</i>, and <i>Power
            BI</i>, including maps, line charts, bar charts, treemaps, and
            network diagrams. I have particular experience using <i>D3.js</i> to
            create fully customized, interactive, and animated visualizations
            that showcase data and provide opportunities for engagement and
            exploration.</div>
          </FadeInDiv>
          <FadeInDiv>
            <div>
              <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
              <span><b>Python & R</b></span>
            </div>
            <div>I have significant experience using both Python and R. For
            Python, this work includes <i>pandas</i> for data importation,
            cleaning, and management; <i>NumPy</i> and <i>NetworkX</i> for
            matrix operations and network analysis; <i>scikit-learn</i> for
            predictive modeling; and object-oriented programming for
            simulation. For R, this work includes <i>dplyr</i>, <i>ggplot2</i>,
            and other <i>tidyverse</i> packages for data importation, cleaning,
            management, and visualization; and <i>caret</i> for predictive
            modeling.</div>
          </FadeInDiv>
          <FadeInDiv>
            <div>
              <span className="icon"><FontAwesomeIcon icon={faMap} size="1x" /></span>
              <span><b>GIS</b></span>
            </div>
            <div>I have used GIS to conduct analyses based on a variety of
            vector and raster data, such as lidar, satellite imagery, and
            building outlines. I have also worked with <i>ArcGIS Online</i> to
            showcase and share geospatial data.</div>
          </FadeInDiv>
          <FadeInDiv>
            <div>
              <span className="icon"><FontAwesomeIcon icon={faDatabase} size="1x" /></span>
              <span><b>Databases</b></span>
            </div>
            <div>I have previous experience working with relational databases
            using <i>SQL</i>, including specific projects that have
            leveraged <i>PostgreSQL</i> and <i>Microsoft Access</i>.</div>
          </FadeInDiv>
      </div>
    </div>
  );
})

const Projects = forwardRef(function Projects(props, ref) {
    return (
      <div id="projects_background" ref={ref} >
        <div id="projects" className="section_ID"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <FlyInLeftDiv><div className="header_statement header_left">Creating a
        variety of projects for different audiences.</div></FlyInLeftDiv>
        <FadeInDiv><div className="header_substatement header_left">My project
        experience has encompassed a range of different datasets, presentation
        formats, and target audiences. Below are a few samples that highlight
        some of my visualization and technical skills. As my background is in
        economics and public policy—specifically as it relates to energy and
        environment—many of these projects use data from, or focus on, those
        topical areas.</div></FadeInDiv>
        <div className="projects">
          <Link to="/industry_output">
            <FadeInDiv>
              <div className="thumbnail"><img src={thumbnailProductionMap}/></div>
              <div>What does the U.S. economy produce? A look at national and state data (D3.js and Leaflet)</div>
            </FadeInDiv>
          </Link>
          <Link to="/production_network">
            <FadeInDiv>
              <div className="thumbnail"><img src={thumbnailProductionNetwork}/></div>
              <div>Exploring the U.S. economic production network (D3.js and Python)</div>
            </FadeInDiv>
          </Link>
          <Link to="/input_categorization">
            <FadeInDiv>
            <div className="thumbnail"><img src={thumbnailInputCategorization}/></div>
            <div>Categorizing U.S. industries' use of petroleum products (D3.js, Python, and R)</div>
            </FadeInDiv>
          </Link>
          <Link to="/community_solar">
            <FadeInDiv>
            <div className="thumbnail"><img src={mpResSuitableArea}/></div>
            <div>Assessing the potential for community solar in Durham, North Carolina (ArcGIS)</div>
            </FadeInDiv>
          </Link>
          <Link to="/isone_weather">
            <FadeInDiv>
            <div className="thumbnail"><img src={isoneWindOutputWindSpeed}/></div>
            <div>Exploring New England wind and solar power as it relates to weather and net imports (Excel)</div>
            </FadeInDiv>
          </Link>
          <Link to="/tetris">
            <FadeInDiv>
            <div className="thumbnail"><img src={thumbnailTetris}/></div>
            <div>For fun: Tetris (D3.js)</div>
            </FadeInDiv>
          </Link>
        </div>
      </div>
    );

});

const Contact = forwardRef(function Contact(props, ref) {
    return (
      <div id="contact_background" ref={ref} >
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="contact" className="section_ID"></div>
        <FadeInDiv><div className="header_block">
          <span className="section_header">
            <p>CONTACT</p>
          </span>
          <span className="section_subheader">
            <p>If you're interested in working with me or learning more about
            my projects, please contact me on <a href="https://www.linkedin.com/in/stuartiler" target="_blank" rel="noreferrer">LinkedIn</a>.
            Thank you for visiting!</p>
          </span>
        </div></FadeInDiv>
        <div className="spacer"></div>
      </div>
    );
})

function MainPage(props) {

  const refAbout = useRef(null);
  const refSkills = useRef(null);
  const refTools = useRef(null);
  const refProjects = useRef(null);
  const refContact = useRef(null);

  React.useEffect(() => {
    document.getElementById("side-menu").checked = false;

    if(props.section === "about") {
      if(refAbout.current) {
        refAbout.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "skills") {
      if(refSkills.current) {
        refSkills.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "tools") {
      if(refTools.current) {
        refTools.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "projects") {
      if(refProjects.current) {
        refProjects.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "contact") {
      if(refContact.current) {
        refContact.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [props]);

  return (
    <div>
      <Splash />
      <About ref={refAbout} />
      <Skills ref={refSkills} />
      <Tools ref={refTools} />
      <Projects ref={refProjects} onProjectSelect={props.handleSelect} />
      <Contact ref={refContact} />
    </div>
  );
}

function IndustryOutput(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <div className="spacer"></div>
      <div className="spacer"></div>

      <div className="explanatory_text">

        <div className="spacer"></div>
        <div className="spacer"></div>
        <h2>What does the U.S. economy produce? A look at national and
        state data</h2>

        <p>The United States has a large and diverse economy that creates
        products and services across a spectrum of different industries.
        Although we often focus on the top-level output of the country&mdash;as
        measured by GDP&mdash;this single number conceals substantial geographic
        differences in the economies of individual states.</p>

        <p>The visualization below aims to investigate this variability in
        production in more detail. I start first with an overview of the U.S.
        economy and then dive into details about individual states.</p>

        <div className="spacer"></div>
        <h4>National-level output</h4>

        <p>The total GDP of the United States was nearly $28 trillion in 2023
        (current dollars). The treemap visualization below shows the breakdown
        of U.S. production across 15 different industry groupings ranging from
        agriculture to manufacturing to transportation (values in millions of
        current dollars; data from <a href="https://www.bea.gov/data/"
        target="_blank" rel="noreferrer">U.S. Bureau of Economic
        Analysis</a>).</p>

        <p>The visualization illustrates that, at the national level, five of
        these high-level industry groups were responsible for more than 60
        percent of GDP in 2023. These groups were: (1) finance, insurance, real
        estate, rental, and leasing; (2) professional and business services; (3)
        government and government enterprises; (4) manufacturing; and (5)
        educational services, health care, and social assistance.</p>

        <p>To see a breakdown of the sub-industries contained within each
        high-level group, <b>click</b> on the group. (Note that only the
        groups that have bolded names can be explored in this way.) To zoom
        back out to the higher-level groups, <b>click</b> anywhere within the
        treemap.</p>

      </div>

      <div className="spacer"></div>
      <IndustryOutputMap index={1} useMap={false}/>
      <div className="spacer"></div>

      <div className="explanatory_text">

        <div className="spacer"></div>
        <h4>State-level output</h4>

        <p>There is significant variation across states in terms of what they
        produce. In the viusalization below, <b>click</b> on the map to the left
        of the treemap (or above the treemap on smaller screens) to see data for
        a specific state. <b>Click</b> again on the same state to zoom back out
        to the national level. As in the visualization above, <b>click</b> on a
        high-level group within the treemap to see the sub-industries within
        it.</p>

        <p>As one example of state variation, the <i>Information</i> industry
        grouping&mdash;which contains sub-industries like motion pictures,
        telecommunications, and web search&mdash;comprised about 5 percent of
        overall national GDP in 2023 but nearly 11 percent of California's state
        GDP in that year. As another example, the <i>Mining, quarrying, and oil
        and gas extraction</i> industry grouping represented about 1.5 percent
        of national GDP in 2023, while it comprised nearly 15 percent of West
        Virginia's state GDP in that same year.</p>

      </div>

      <div className="spacer"></div>
      <IndustryOutputMap index={2} useMap={true}/>
      <div className="spacer"></div>
      <div className="spacer"></div>

    </div>
  );
}

class ExploreVisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Exploring the U.S. economic production network</h2>

          <p>As shown in the <Link to="/industry_output">industry output</Link> visualization,
          the United States produces goods and services across a wide variety of
          industries. However, looking at industries in this way suggests that
          industries are isolated and disconnected, which is actually the
          opposite of the reality: industries are fundamentally interconnected
          and interdependent, purchasing from&mdash;and selling to&mdash;each
          other in a complex web of interactions.</p>

          <p>A production network is a conceptualization of the economy that
          explicitly takes account of these linkages, where producing entities
          (such as industries) purchase inputs from other producing entities,
          combine those inputs to create an output, and then sell that output to
          other entities. Production networks provide a unique lens through
          which to understand how changes to one part of the economy can affect
          other parts of the economy.</p>

          <p>As an example, consider the diagram below of four industries. The
          focus industry (shown in red) uses the outputs of three other
          industries: A, B, C. It combines the outputs from these suppliers to
          create its own output, which is then used by other industries
          downstream of it in the production network. To make this more
          concrete, we could think of the focus industry as retail clothing
          stores, which buy clothes from the clothing manufacturing industry
          (Supplier A), rent retail space from the real estate industry
          (Supplier B), and use electricity from the utilities industry
          (Supplier C). The clothing stores then sell the clothes to consumers
          as well as to businesses in other industries.</p>

          <div className="spacer"></div>
          <img className="small_static_image" src={diagramSingleIndustry}/>
          <div className="spacer"></div>

          <p>The custom, interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>&mdash;shows
          these relationships for the U.S. economic production network
          as it was in 2015. As in the example above, the producing entities are
          U.S. industries and, specifically, are the 71 industries (including
          government) contained within the U.S. Bureau of Economic
          Analysis' <a href="https://www.bea.gov/industry/input-output-accounts-data" target="_blank" rel="noreferrer">input-output tables</a> for
          2015. As shown in the visualization, each of these industries is
          associated with a code. For example, the code for the "Farms"
          industry is "111CA."</p>

          <p>The visualization works by focusing on a single industry at a time.
          This "focus" industry is shown in red in the center of the
          visualization, while its direct suppliers (i.e., the industries it
          purchases from) are shown in orange above it and its direct customers
          (i.e., the industries it sells to) are shown in green below it. A pair
          of industries may have a mutual dependence such that they purchase
          from, and sell to, each other. As an example, the "Farms" industry
          (code 111CA) purchases from, and sells to, the "Chemical products"
          industry (code 325). Where these types of relationships occur, the
          relevant suppliers/customers are shown in purple to the right of the
          focus industry. For the sake of clarity, the visualization is limited
          to displaying the top five suppliers and the top five customers for
          each focus industry (where, as described, a particular industry's
          supplier may also be its customer, and in that case the
          supplier/customer is shown in purple to the side). <b>Hover</b> over
          an industry to see more information. <b>Click</b> on an industry to
          change the focus.</p>
        </div>
      </div>
    )
  }
}

class ExploreVisBottomText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>A focus industry's direct suppliers and customers (its
          "first-degree" connections) capture just one aspect of its
          connectedness within the broader U.S. production network. The
          visualization also displays some "second-degree" connections:
          a focus industry's suppliers' suppliers as well as its customers'
          customers. These connections are shown in grey at the top and the
          bottom of the visualization, respectively.</p>

          <p>Finally, on the right (or below on smaller screens) is a beeswarm
          plot that illustrates each industry's "upstreamness." Although all
          industries have connections throughout the U.S. production network,
          some industries tend to sell most (or potentially all) of their output
          to final consumers rather than to other industries. Upstreamness is
          essentially a measure of how far away an industry is from this type of
          final demand, where a greater upstreamness value reflects a greater
          distance. For this visualization, I use an upstreamness definition
          based on <a
          href="https://www.aeaweb.org/articles?id=10.1257/aer.102.3.412"
          target="_blank" rel="noreferrer">Antràs et al. (2012)</a>, which yields upstreamness
          values for the 71 industries in 2015 ranging from one (most
          downstream) to 4.5 (most upstream). The most upstream industry is
          "Support activities for mining" (code 213), while there are several
          industries tied for most downstream, including "Hospitals" (code 622),
          "Housing" (code HS), and "Food and beverage stores" (code 445).</p>
        </div>
      </div>
    )
  }
}

class ExploreVis extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreVisTopText />
        <ProductionNetwork graph={this.props.graphData} />
        <ExploreVisBottomText />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/network-visualization" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class ExploreCatTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Categorizing U.S. industries' use of petroleum products</h2>

          <p>As illustrated by the <Link to="/production_network">exploring
          production networks</Link> visualization, the U.S. economy can be
          thought of as a network of industries, where each industry purchases
          inputs from other industries, combines those inputs to create an
          output, and then sells that output to other industries downstream. One
          of the advantages of the production networks conceptualization is that
          it provides a lens through which to explore the propagation of shocks,
          and one of the key factors determining how such shocks are transmitted
          is the ability (or inability) of industries to switch among
          their inputs.</p>

          <p>To explore these dynamics in more detail, I consider here how U.S.
          industries use the outputs of the petroleum products industry<a href="#star">*</a> (such
          as gasoline and diesel fuel) in relation to their other inputs. I
          choose the petroleum products industry both because it is a central
          input in the production network and because its price has often been
          volatile.</p>

          <p>Many industries use petroleum products as a <i>complement</i> to
          other inputs, in the sense that when they use more petroleum
          products, they also tend to use more of those other inputs. As an
          example, the wood products industry might combine the outputs of the
          forestry industry and petroleum products in a complementary way, given
          that they need fuel to run the machinery that converts harvested
          timber into finished goods (such as lumber and flooring).</p>

          <div className="spacer"></div>
          <img className="small_static_image" src={diagramComplements}/>
          <div className="spacer"></div>

          <p>Conversely,
          some industries may use petroleum products as a <i>substitute</i> for
          other inputs, in the sense that when they use more petroleum products,
          they tend to use less of those other inputs. For example, the rail
          transportation industry might use the outputs of the utilities
          industry (such as electric power) and petroleum products as
          substitutes, given that some of their operations may be able to switch
          between different types of energy sources.</p>

          <div className="spacer"></div>
          <img className="small_static_image" src={diagramSubstitutes}/>
          <div className="spacer"></div>

          <h4>Price shock propagation: upstream to downstream</h4>

          <p>Price changes tend to move from upstream to downstream in the
          production network (that is, from suppliers to customers). The reason
          is that when a producing entity faces a price change, it may pass
          through some of that change to its customers, which then do the same
          with their own customers, and so on. Previous research suggests that
          when inputs are used as complements, it may lead to greater price
          shock propagation than when inputs are used as substitutes. The
          intuition is that, in the complements case, producing entities have
          less flexibility to switch among their inputs when prices fluctuate,
          which may lead them to modify the prices of their own products and
          services more than they otherwise would. This dynamic is then
          compounded over multiple levels in the production network, which leads
          to greater shock propagation.</p>

          <p>The interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>&mdash;allows
          users to explore the proportions of U.S. industries' upstream inputs
          that have been categorized as complements to petroleum products,
          substitutes for petroleum products, or neither. The categorizations
          are based on my <a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank" rel="noreferrer">dissertation research</a>,
          which proposes an approach to generate these types of categorizations
          using machine learning and publicly available data (this visualization
          makes use of data from
          the <a href="https://www.bea.gov/" target="_blank" rel="noreferrer">U.S. Bureau of Economic Analysis</a>).
          The visualization takes into account two degrees of upstream
          connections: industries' direct suppliers as well as those suppliers'
          suppliers. <b>Click</b> on the buttons above the chart to re-sort
          based on each category.</p>

          <p>As shown in the chart, a large portion of industries' upstream
          inputs are not categorized as either complements to or substitutes for
          petroleum products. The reason is that, essentially, the approach did
          not detect a strong enough usage pattern for those inputs vis-a-vis
          petroleum products. For additional details, see the full Python, R,
          and D3.js source code for the categorization and visualization
          on <a href="https://github.com/stuartiler/input-categorization" target="_blank" rel="noreferrer">GitHub</a>.</p>
        </div>
      </div>
    )
  }
}

class ExploreCatMiddleText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>One pattern to note is that most industries use petroleum products
          as a complement to at least some of their other inputs. This provides
          a partial explanation for why historical oil price increases have had
          substantial economic effects throughout the production network even
          when individual industries' expenditures on petroleum products have
          been relatively small. Re-sorting the chart by substitutes shows that
          many (though not all) of the industries using petroleum products as a
          substitute to other inputs are service industries.</p>

          <h4>Demand shock propagation: downstream to upstream</h4>

          <p>Unlike prices, demand changes tend to move from downstream to
          upstream in the production network (that is, from customers to
          suppliers). The reason is that when a producing entity faces a demand
          change, it tends to increase or decrease demand for the inputs
          provided by its suppliers, which those suppliers may then do with
          their own suppliers, and so on.</p>

          <p>When industries demand more petroleum products, they will also tend
          to demand more of the inputs that they use as complements to petroleum
          products. The industries that make those complementary products then
          also experience an increase in demand. Conversely, when industries
          demand less petroleum products, they will tend to demand more of the
          inputs that they use as substitutes for petroleum products. The
          industries that produce those substitute products will then experience
          an increase in demand.</p>

          <p>The visualization below shows the proportions of U.S. industries'
          downstream customers that use their output as complements to petroleum
          products, substitutes for petroleum products, or neither (as
          categorized by the same approach described above). Also similar to
          above, the visualization takes account of two degrees of downstream
          connections: industries' direct customers as well as those customers'
          customers. <b>Click</b> on the buttons above the chart to re-sort
          based on each category.</p>

          <p>The key takeaway is that industries with a greater proportion of
          downstream complements will tend to experience higher demand when
          there is higher demand for petroleum products (and vice-versa), while
          industries with a greater proportion of downstream substitutes will
          tend to experience higher demand when there is lower demand for
          petroleum products (and vice-versa). Note that a number of industries'
          outputs are used as complements by some of their customers and as
          substitutes by others; depending on the situation, these industries
          may face conflicting forces.</p>
        </div>
      </div>
    )
  }
}

class ExploreCat extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreCatTopText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"upstream"} index={0} />
        <ExploreCatMiddleText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"downstream"} index={1} />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="star">*This analysis focuses on the 3-digit NAICS industry
        "petroleum and coal products," which for simplicity I refer to as
        "petroleum products."</div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/input-categorization" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class TetrisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Tetris with JavaScript and D3.js</h2>

          <p>Below is an interactive Tetris game I built with JavaScript
          and <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>. Source
          code for game is available
          on <a href="https://github.com/stuartiler/d3-tetris" target="_blank" rel="noreferrer">GitHub</a>.</p>

          <p>Press the arrow keys the on the keyboard to control the falling
          piece. <b>Left</b> and <b>right</b> will move the piece left and
          right, respectively. <b>Up</b> will rotate the piece clockwise,
          and <b>down</b> will push the piece as far down as it can go. (Note
          that scrolling the browser window with the arrow keys has been
          disabled for this page.)</p>

          <p>When one or more horizontal lines are completed, the lines will
          disappear and the remaining blocks will be shifted down. The score is
          increased ten points for every line cleared, with a bonus for the
          number of lines cleared simultaneously. If the board becomes
          completely filled with blocks, it's game over and everything is
          reset.</p>
        </div>
      </div>
    )
  }
}

class PlayTetris extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <TetrisTopText />
        <div className="spacer"></div>
        <Tetris />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for the game is available
        on <a href="https://github.com/stuartiler/d3-tetris" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

function ParallaxImage({scrollY,
                        topLidar,
                        topTIN,
                        topDSM,
                        topSolarRadiation,
                        topNDVI,
                        topSuitableArea}) {

  const [currImg, setCurrImg] = useState(mpStudyAreas);

  useMotionValueEvent(scrollY, "change", (latest) => {

    let adjustment_distance = -50;

    let imgChange =
      latest > topSuitableArea + adjustment_distance ? mpResSuitableArea
      : latest > topNDVI + adjustment_distance ? mpResNDVI
      : latest > topSolarRadiation + adjustment_distance ? mpResSolarRadiation
      : latest > topDSM + adjustment_distance ? mpResDSM
      : latest > topTIN + adjustment_distance ? mpResTIN
      : latest > topLidar + adjustment_distance ? mpResLidar
      : mpStudyAreas;

    setCurrImg(imgChange);

  })

  return (
    <>
      <motion.img className="parallax_image"
                  key={currImg}
                  src={currImg}
                  initial={{opacity: 0.5}}
                  animate={{src: currImg, opacity: 1}}
                  transition={{duration: 1.5}} />
    </>
  );
}

function CommunitySolar(props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    if (refLidar.current) {
      const { top } = refLidar.current.getBoundingClientRect();
      setTopLidar(top);
    }

    if (refTIN.current) {
      const { top } = refTIN.current.getBoundingClientRect();
      setTopTIN(top);
    }

    if (refDSM.current) {
      const { top } = refDSM.current.getBoundingClientRect();
      setTopDSM(top);
    }

    if (refSolarRadiation.current) {
      const { top } = refSolarRadiation.current.getBoundingClientRect();
      setTopSolarRadiation(top);
    }

    if (refNDVI.current) {
      const { top } = refNDVI.current.getBoundingClientRect();
      setTopNDVI(top);
    }

    if (refSuitableArea.current) {
      const { top } = refSuitableArea.current.getBoundingClientRect();
      setTopSuitableArea(top);
    }
  }, []);

  const { scrollY } = useScroll();

  const refLidar = useRef(null);
  const refTIN = useRef(null);
  const refDSM = useRef(null);
  const refSolarRadiation = useRef(null);
  const refNDVI = useRef(null);
  const refSuitableArea = useRef(null);

  const [topLidar, setTopLidar] = useState(0);
  const [topTIN, setTopTIN] = useState(0);
  const [topDSM, setTopDSM] = useState(0);
  const [topSolarRadiation, setTopSolarRadiation] = useState(0);
  const [topNDVI, setTopNDVI] = useState(0);
  const [topSuitableArea, setTopSuitableArea] = useState(0);

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (refLidar.current) {
      const { top, left, bottom, right } = refLidar.current.getBoundingClientRect();
      setTopLidar(top);
    }

    if (refTIN.current) {
      const { top, left, bottom, right } = refTIN.current.getBoundingClientRect();
      setTopTIN(top);
    }

    if (refDSM.current) {
      const { top, left, bottom, right } = refDSM.current.getBoundingClientRect();
      setTopDSM(top);
    }

    if (refSolarRadiation.current) {
      const { top, left, bottom, right } = refSolarRadiation.current.getBoundingClientRect();
      setTopSolarRadiation(top);
    }

    if (refNDVI.current) {
      const { top, left, bottom, right } = refNDVI.current.getBoundingClientRect();
      setTopNDVI(top);
    }

    if (refSuitableArea.current) {
      const { top, left, bottom, right } = refSuitableArea.current.getBoundingClientRect();
      setTopSuitableArea(top);
    }
  }, [windowSize.width, windowSize.height]);

  return (
    <div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="explanatory_text">
        <div className="spacer"></div>
        <div className="spacer"></div>

        <h2>Assessing the potential for community solar in Durham, NC</h2>

        <p>For my <a href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf" target="_blank" rel="noreferrer">master's thesis</a> at
        Duke University, I used Geographic Information Systems (GIS) to
        explore the potential for community solar in Durham, North Carolina
        (where Duke is located).</p>

        <p>When we think about rooftop solar panels, we often envision a solar
        system on top of a single-family home.  Community solar is instead a
        "solar-electric system that, through a voluntary program, provides power
        and/or financial benefit to, or is owned by, multiple community members"
        (<a href="https://www.nrel.gov/docs/fy11osti/49930.pdf"
        target="_blank" rel="noreferrer">Coughlin et al., 2010</a>). Two of the primary goals of
        community solar are to increase access to solar energy and to reduce
        initial costs. Other potential benefits include better economies of
        scale, increased flexibility in choosing an optimal solar site, and the
        possibility of local job creation.</p>

        <p>In this research, I explored community solar by: (1) estimating the
        cost and solar potential of hypothetical systems across approximately
        2,000 parcels in Durham; and (2) analyzing the differences in cost and
        solar potential by type of parcel. The idea was to proxy for the
        benefits of community solar as compared to individually-owned
        residential rooftop systems by considering commercial and community
        service parcels on one hand (where community solar projects are more
        likely to be located) and residential parcels on the other.</p>

        <p>Scroll down for a high-level overview of the methods, results, and
        conclusions of the study.</p>

        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>

        <div className="parallax_block">

          <div className="parallax_text">

            <p>I first chose three study areas: a residential area north
            of Duke University’s East Campus (outlined in green); the greater
            downtown Durham area (outlined in red); and the majority of Duke
            University’s West Campus (outlined in yellow).</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpStudyAreas}/>
            <div className="parallax_image_spacer"/>

            <p>I estimated the cost and solar potential of hypothetical
            photovoltaic systems on the parcels across these areas in a few
            steps. I started by obtaining Light Detection and Ranging (LIDAR)
            data for the study areas from the U.S. Geological Survey. LIDAR data
            provides accurate elevation measurements by using laser technology
            from the air.</p>

            <div className="parallax_spacer" ref={refLidar}></div>

            <p>This image is an example of the LIDAR data points (in yellow) on and
            around an elementary school in the residential study area. Note that
            the data points represent the elevation of the highest object at each
            point, such as a building, tree, or the ground.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResLidar}/>

            <div className="parallax_spacer" ref={refTIN}></div>


            <p>I used these points to create a Triangulated Irregular Network
            (TIN) model: a 3-dimensional surface where individual points are
            connected by lines to generate a set of interconnected triangles. This is the
            first step in moving from a collection of individual points to a
            continuous surface.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResTIN}/>


            <div className="parallax_spacer" ref={refDSM}></div>

            <p>I then converted the  vector-based TIN to a raster-based
            digital surface model (DSM). I performed this conversion using the
            natural neighbors interpolation method, choosing for the output
            raster to have a cell size of one square foot. The resulting DSM
            represents the height of objects in every square foot
            across the three study areas.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResDSM}/>


            <div className="parallax_spacer" ref={refSolarRadiation}></div>

            <p>ArcGIS has a tool called "area solar radiation" that, combined with
            a given DSM, can calculate the aggregate solar radiation at each point
            over a specified period of time. I used this tool to estimate the
            solar radiation across the three study areas for calendar year 2012.
            The calculated radiation is given in watt-hours per square meter.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResSolarRadiation}/>


            <div className="parallax_spacer" ref={refNDVI}></div>

            <p>Before estimating properties of the solar systems that could be
            installed on the study parcels, I first wanted to account for the fact
            that many building rooftops are partially or fully shaded by trees. To
            do this, I made use of aerial imagery from the U.S. Department of
            Agriculture.</p>

            <p>Specifically, in addition to the typical red, green, and blue
            visible color bands, this imagery also included an infrared band
            suitable for calculating the Normalized Difference Vegetation Index
            (NDVI). For biological reasons, plants tend to absorb electromagnetic
            radiation in the visible red light part of the spectrum while
            reflecting near infrared radiation; the NDVI essentially measures the
            difference between these two to identify the amount of vegetation over
            an area.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResNDVI}/>
            <div className="parallax_image_spacer"/>

            <p>The result is a raster with values ranging from -1 to 1, with
            higher values indicating increased levels of vegetation.</p>


            <div className="parallax_spacer" ref={refSuitableArea}></div>

            <p>With these parts of the analysis completed, I then summed the solar
            radiation across the building footprint(s) on each parcel, ignoring
            any areas (1) where the NDVI indicated the likely presence of
            vegetation and/or (2) where the solar radiation was not above a
            given threshold (based on previous research regarding what would be
            economical for photovoltaic system installation).</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResSuitableArea}/>
            <div className="parallax_image_spacer"/>

            <p>I also determined the rooftop <i>area</i> associated with each sum,
            which allowed me to estimate the cost of the PV systems taking into
            account economies of scale.</p>

            {windowSize.width > 1600 ? <><div className="spacer"></div><div className="spacer"></div></> : null}
            {windowSize.width > 1900 ? <><div className="spacer"></div><div className="spacer"></div></> : null}
          </div>

          <div className="parallax_image_container">
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <ParallaxImage scrollY={scrollY}
                           topLidar={topLidar}
                           topTIN={topTIN}
                           topDSM={topDSM}
                           topSolarRadiation={topSolarRadiation}
                           topNDVI={topNDVI}
                           topSuitableArea={topSuitableArea} />
          </div>

        </div>

        <div className="spacer"></div>
        {windowSize.width < 1600 ? <div className="spacer"></div>: null}
        {windowSize.width < 1900 ? <div className="spacer"></div>: null}

        <h2>Results of the Study</h2>

        <p>To investigate differences in system costs across different types
        of parcels, I calculated an energy cost ratio for each system:
        the estimated cost of the system (in dollars) divided by the estimated
        annual energy output of the system (in kWh). Note that this metric
        does not represent the cost of electricity over the lifetime of the
        systems but is rather intended as simple proxy for the purpose of
        comparison.</p>

        <p>The averages of the energy cost ratio for each of the three parcel
        types are shown in the table below. The cost is highest for
        residential parcels, and the differences in cost between residential
        parcels and the other two parcel types are statistically significant.
        Along with some of the other results I present in the thesis, this
        suggests that there may be  something to be gained for the average
        residential parcel–in terms of energy received for the investment
        cost–by pooling funds and installing a system that is optimally
        sited on a well-chosen commercial or community service site.</p>

        <div className="spacer"></div>
        <table>
          <tbody>
            <tr>
              <th>Parcel Type</th>
              <th>Number of Parcels</th>
              <th>Average Energy Cost ($/kWh)</th>
            </tr>
            <tr>
              <td>Residential</td>
              <td>1,469</td>
              <td>5.63</td>
            </tr>
            <tr>
              <td>Commercial</td>
              <td>363</td>
              <td>5.24</td>
            </tr>
            <tr>
              <td>Community Service</td>
              <td>82</td>
              <td>5.26</td>
            </tr>
          </tbody>
        </table>
        <div className="spacer"></div>

        <p>In terms of access to renewable energy, I found that approximately
        10 percent of residential parcels did not have rooftop areas suitable
        for PV systems. An additional 18 percent of residential parcels had
        rooftop areas suitable for systems of 1 kilowatt or less. Taken
        together, this suggests that nearly a third of the residential
        parcels I considered in the Durham study areas had little or no access
        to solar energy investment opportunities.</p>

        <h2>Key Takeaways</h2>

        <p>So overall, could community solar reduce the costs of solar
        photovoltaic systems in Durham? I said likely yes, though it depends
        on which two systems are being compared. Looking across the three
        study areas, larger buildings–which tend to be community service or
        commercial buildings–more often exhibited better cost efficiencies.
        They were not always the best, however, and a homeowner with a
        particularly suitable rooftop would not be gaining anything in terms of
        cost efficiency through participation in a community solar venture.</p>

        <p>Could community solar increase access to the benefits of renewable
        solar energy in Durham? I said very likely yes, though the details
        of the community solar implementation would need to be outlined. In
        any case, for the nearly 30 percent of residential parcels with little
        or no suitable rooftop space, the ability to invest in a community
        solar project would be a huge access improvement over the status quo.
        That would also be the case for individuals that live in apartments
        and for homeowners that have suitable rooftop area but are unwilling
        or unable to invest in a full solar photovoltaic system.</p>
      </div>
      <div className="spacer"></div>
      <div className="spacer"></div>
    </div>
  );

}

class ISONewEngland extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <div className="spacer"></div>
          <div className="spacer"></div>

          <h2>Exploring ISO New England wind and solar power as it relates to weather and net imports</h2>

          <p>Both as a resident of Massachusetts and as someone who formerly
          worked on climate mitigation for the state, I have an interest in the
          regional New England electricity supply, especially as it pertains to
          renewables and emissions. In this project, I conducted a simple
          exploration of wind and solar power in the <a href="https://www.iso-ne.com/" target="_blank" rel="noreferrer">ISO New England (ISO-NE)</a> electricity
          resource mix for a set of sample days in calendar year 2022.</p>

          <p>Specifically, I retrieved the resource mix for the first day of
          each month in 2022 using ISO-NE's <a href="https://www.iso-ne.com/isoexpress/" target="_blank" rel="noreferrer">ISO Express</a> data
          product. These data points are at the sub-hourly level, which resulted
          in a dataset with 2,357 observations. I then retrieved historical
          weather data measured at Logan International Airport in Boston for
          these same days in 2022 from <a href="https://www.wunderground.com/" target="_blank" rel="noreferrer">Weather Underground</a>, including
          information about wind speed and general conditions. These
          measurements are generally at the hourly level, which resulted in a
          dataset with 360 weather observations.</p>

          <p>I note that these weather data, representing conditions in coastal
          Massachusetts, are likely a better proxy for conditions at wind farms
          in the region than they are for solar farms in the region. In
          addition, for both wind and solar, a single geographic point of
          measurement is of course quite coarse to represent multiple generation
          points.</p>

          <p>This said, with these caveats in mind, I used Microsoft Excel to
          match each row in the power dataset to a row in the weather dataset,
          where a "match" was determined by finding the weather measurement that
          most closely preceded in time each power observation. I then created
          flags to indicate the weather condition (such as cloudy versus fair)
          as well as the season of the year (winter, spring, summer, or fall). A
          copy of the Excel file can be downloaded <a href={isoneExcel} target="_blank" rel="noreferrer">here</a>.</p>

          <h4>Wind Output and Weather</h4>

          <p>With this in hand, I first used Excel to plot the relationship
          between wind power output in megawatts (MW) and wind speed in miles
          per hour (mph) during the sample period (see below; note that the
          values on the horizontal axis should be interpreted as wind speed
          categories, as there are several values missing between 0 mph and 29
          mph).</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneWindOutputWindSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind speed, the graph shows the individual power
          data points as well as the average (symbolized by an "x") and the
          range of values between the 25th and 75th percentiles (indicated by
          the green vertical boxes; note that the boxes for the categories 25
          mph and 29 mph appear as horizontal lines because there is only one
          observation for each of those two categories).</p>

          <p>As we might expect, the graph generally suggests that the higher
          the wind speed, the greater the wind power in the ISO-NE resource
          mix. A regression of wind output against wind speed and wind gust
          speed (see below) suggests that about 46 percent of the variation in
          wind output during the sample period can be explained by these two
          speed variables alone.</p>

          <p>I also used Excel to plot the relationship between wind power
          output and wind gust speed during the sample period (as above, the
          values on the horizontal axis should be interpreted as wind speed
          categories, as there are values missing between 0 mph and 40 mph).</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneWindOutputWindGustSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind gust speed, the graph shows the individual
          power data points as well as the average (symbolized by an "x") and
          the range of values between the 25th and 75th percentiles (indicated
          by the orange vertical boxes; note that the box for the category 40
          mph appears as a horizontal line because there is only one observation
          for that category).</p>

          <p>The graph suggests that the higher the wind gust speed, the greater
          the wind power in the ISO-NE resource mix. As noted above, a
          regression of wind output against wind speed and wind gust speed
          suggests that about 46 percent of the variation in wind output during
          the sample period can be explained by those two speed variables. The
          coefficient on wind gust speed is statistically significant even when
          controlling for wind speed. It may be that gusts contribute to
          generation directly and/or that they are correlated with environmental
          and other variables that are favorable for wind production and
          dispatch.</p>

          <h4>Solar Output and Weather</h4>

          <p>The graph below illustrates the relationship between average solar
          power output (yellow bars) and the number of fair weather readings in
          each season (blue circles; for this graph, each "fair weather reading"
          is a time period in the sample in which the weather condition was
          recorded as either "Fair" or "Fair / Windy").</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneSolarOutputFairWeather} />
          <div className="spacer"></div>

          <p>The graph suggests that solar power was highest in the ISO-NE
          resource mix during the summer, followed by the spring, fall, and
          winter (in that order). The graph also suggests that, at the seasonal
          level, solar generation is highly correlated with the number of fair
          weather readings.</p>

          <p>Although such a relationship appears at this more aggregated level,
          a regression of solar output against indicators for each fair
          condition ("Fair" and "Fair / Windy") at the individual observation
          level suggests that these conditions alone do not explain much of the
          solar variation in the dataset (which is likely partially due to the
          geographic coarseness of the weather data, with one weather station
          serving as a proxy for general conditions in the region). This said,
          the coefficients on the indicators are statistically significant at
          the one percent level.</p>

          <h4>Wind and Solar Output and Net Imports</h4>

          <p>The graph below illustrates the relationship between wind power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneNetImportsWindOutput} />
          <div className="spacer"></div>

          <p>The graph suggests a somewhat negative relationship between these
          two variables (the Pearson correlation coefficient is approximately
          -0.18). This does not necessarily imply that increases in wind power
          "backed out" (i.e., caused a decrease in) net imports on the margin
          during this period, though a regression of net imports on total
          generation, natural gas, nuclear, wind, and solar suggests that this
          type of negative relationship is stronger for wind than it is for
          either natural gas or nuclear.</p>

          <p>ISO-NE's real-time data on CO2 emissions do not include emissions
          estimates for imported electricity "because the resource types
          producing this electricity are unspecified" (see <a href="https://www.iso-ne.com/isoexpress/" target="_blank" rel="noreferrer">ISO Express</a> for
          details). To the extent that wind power did reduce the need for
          electricity imports, it is unclear how such dynamics impacted overall
          CO2 emissions.</p>

          <p>The graph below illustrates the relationship between solar power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneNetImportsSolarOutput} />
          <div className="spacer"></div>

          <p>Even more than in the case of wind, the graph suggests a negative
          relationship between these two variables (the Pearson correlation
          coefficient is approximately -0.42). As above, this does not
          necessarily imply that increases in solar power "backed out" the need
          for net imports on the margin during this period, though a regression
          of net imports on total generation, natural gas, nuclear, wind, and
          solar suggests that such a negative relationship is stronger for solar
          than it is for natural gas, nuclear, or wind. For more information
          about solar power in the ISO-NE resource mix, see <a href="https://www.iso-ne.com/about/what-we-do/in-depth/solar-power-in-new-england-locations-and-impact" target="_blank" rel="noreferrer">Solar Power in New England: Concentration and Impact</a>.</p>

          <p>Also as above, to the extent that solar power did reduce the need
          for electricity imports, it is unclear how such dynamics impacted
          overall CO2 emissions during the sample period as data on the CO2
          intensity of the imports are not available.</p>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

function App() {
  return (
    <div className="App">
      <TopBar />
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/about" element={<MainPage section={"about"} />}/>
        <Route path="/skills" element={<MainPage section={"skills"} />}/>
        <Route path="/tools" element={<MainPage section={"tools"} />}/>
        <Route path="/projects" element={<MainPage section={"projects"} />}/>
        <Route path="/contact" element={<MainPage section={"contact"} />}/>
        <Route path="/industry_output" element={<IndustryOutput />}/>
        <Route path="/production_network" element={<ExploreVis graphData={graphData} />}/>
        <Route path="/input_categorization" element={<ExploreCat catData={categoryData} />}/>
        <Route path="/community_solar" element={<CommunitySolar />}/>
        <Route path="/isone_weather" element={<ISONewEngland />}/>
        <Route path="/tetris" element={<PlayTetris />}/>
      </Routes>
      <BottomBar />
    </div>
  );
}

export default App;
